import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box, CircularProgress } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import AdminLogin from '../pages/Admin/AdminLogin/AdminLogin';
import SurveyList from '../pages/Admin/Surveys/List';
import AdminLayout from '../components/Layout/AdminLayout';
import { setUser } from '../store/slices/authSlice';
import { checkAuthStatus } from '../api/admin';
import SurveyForm from '../pages/Admin/Surveys/Form';
import RespondentsPage from '../pages/Admin/Respondents';
import BlackList from '../pages/Admin/BlackList/BlackList';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  if (!isAuthenticated) {
    return <AdminLogin />;
  }

  return <AdminLayout>{children}</AdminLayout>;
};

const Admin = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        setLoading(true);
        const response = await checkAuthStatus();
        if (response) {
          dispatch(setUser(response));
          setLoading(false);
        }
      } catch (error) {
        console.error('Auth check failed:', error);
        setLoading(false);
      }
    };

    checkAuth();
  }, [dispatch]);


  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Routes>
        <Route path="/login" element={<AdminLogin />} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <SurveyList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/surveys"
          element={
            <ProtectedRoute>
              <SurveyList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/surveys/create"
          element={
            <ProtectedRoute>
              <SurveyForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/surveys/edit/:id"
          element={
            <ProtectedRoute>
              <SurveyForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/blacklist"
          element={
            <ProtectedRoute>
              <BlackList />
            </ProtectedRoute>
          }
        />
        <Route
          path="respondents"
          element={
            <ProtectedRoute>
              <RespondentsPage />
            </ProtectedRoute>
          }
        />
      </Routes>
    </ThemeProvider>
  );
};

export default Admin;
