import React from 'react';
import { TextField } from '@mui/material';
import { useField } from 'formik';
import { styled } from '@mui/material/styles';

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      backgroundColor: 'transparent',
    },
  },
});

export const FormField = ({ name, ...props }) => {
  const [field, meta] = useField(name);
  
  return (
    <StyledTextField
      fullWidth
      {...field}
      {...props}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
    />
  );
}; 