import { useNavigate, useParams } from 'react-router-dom';
import { useSurvey } from '../../context/SurveyProvider';
import BackgroundAssets from '../BackgroundAssets/BackgroundAssets';
import { getFailedQuizAmount } from '../../utils/utils';
import { useEffect } from 'react';

const Sorry = () => {
  const { surveyName } = useParams();
  const { surveyData } = useSurvey();
  const navigate = useNavigate();
  const quizFailedTriesAmount = getFailedQuizAmount(surveyData.surveyId);

  useEffect(() => {
    if (surveyData?.name && surveyData?.episode) {
      document.title = `Survey : ${surveyData?.episode} : Sorry`;
    }
  }, [surveyData]);

  return (
    <div className="step-last">
      <BackgroundAssets />
      <main className="thank-you sorry">
        <div className="wrapper">
          <h1>SORRY!</h1>
          {quizFailedTriesAmount >= 3 || surveyData.mistakes >= 3 ? (
            <fieldset>
              <p>3 strikes and you're out!</p>
              <p>Sorry, but we really need people to listen to the podcast to give valuable feedback.</p>
            </fieldset>
          ) : (
            <fieldset>
              <p>
                You need to answer those survey questions correctly! Are you sure you listened to the podcast?
              </p>
              <p>
                Listen to the podcast{' '}
                <a
                  href={surveyData.spotifyEpisodeLink || surveyData.spotifyLink}
                  target="_blank"
                  id="back-and-listen"
                >
                  on Spotify
                </a>{' '}
                or{' '}
                <a
                  href={surveyData.appleEpisodeLink || surveyData.appleLink}
                  target="_blank"
                  id="back-and-listen"
                >
                 Apple Podcasts
                </a>{' '}
                and take the survey{' '}
                <span className="try-again" onClick={() => navigate(`/survey/${surveyName}/quiz`)}>
                  again
                </span>
              </p>
            </fieldset>
          )}
        </div>
      </main>
    </div>
  );
};
export default Sorry;
