import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SurveyPage from './pages/SurveyPage/SurveyPage';
import Onboarding from './pages/Onboarding/Onboarding';
import { Provider } from 'react-redux';
import { store } from './store/store';
import Admin from './routes/Admin';

function App() {

  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/survey/:surveyName/:stepName?" element={<SurveyPage />} />
          <Route path="/onboarding" element={<Onboarding />} />
          <Route path="/admin/*" element={<Admin />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
