import axios from 'axios';
import { getHeaders } from '../utils/utils';

export const login = async (data) => axios.post(`/api/admin/login`, data);
export const logout = async () => {
  localStorage.removeItem('auth_token');
};

export const checkAuthStatus = async () => {
  const headers = getHeaders();
  try {
    const response = await axios.get('/api/admin/auth-status', { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSurveys = async (params) => {
  const headers = getHeaders();
  try {
    const response = await axios.get(`/api/admin/surveys`, { headers, params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createSurvey = async (surveyData) => {
  const headers = getHeaders();
  try {
    const response = await axios.post('/api/admin/surveys', surveyData, { headers });
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || error.message;
  }
};

export const updateSurvey = async (id, surveyData) => {
  const headers = getHeaders();
  try {
    const response = await axios.put(`/api/admin/surveys/${id}`, surveyData, { headers });
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || error.message;
  }
};

export const getSurveyById = async (id) => {
  const headers = getHeaders();
  try {
    const response = await axios.get(`/api/admin/surveys/${id}`, { headers });
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || error.message;
  }
};

export const uploadImage = async (file) => {
  const headers = {
    ...getHeaders(),
    'Content-Type': 'multipart/form-data',
  };

  const formData = new FormData();
  formData.append('image', file);

  try {
    const response = await axios.post('/api/admin/upload-image', formData, { headers });
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'Failed to upload image';
  }
};

export const getRespondents = async (params) => {
  const headers = getHeaders();
  try {
    const response = await axios.get('/api/admin/respondents', { headers, params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getBlacklist = async (params) => {
  const headers = getHeaders();
  try {
    const response = await axios.get('/api/admin/blacklist', { headers, params });
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || error.message;
  }
};

export const addToBlacklist = async (emails) => {
  const headers = getHeaders();
  try {
    const response = await axios.post('/api/admin/blacklist', { emails }, { headers });
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || error.message;
  }
};

export const removeFromBlacklist = async (email) => {
  const headers = getHeaders();
  try {
    const response = await axios.delete(`/api/admin/blacklist/${encodeURIComponent(email)}`, {
      headers,
    });
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || error.message;
  }
};

export const downloadRespondentsCsv = async (surveyId) => {
  const headers = getHeaders();
  try {
    const response = await axios.get(`/api/admin/respondents-csv`, {
      headers,
      params: { survey: surveyId },
      responseType: 'blob',
    });

    // Create a URL for the blob and trigger download
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `respondents-${surveyId}.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    throw error.response?.data?.message || error.message;
  }
};
