export const initialValues = {
  name: '',
  slug: '',
  rssLink: '',
  appleLink: '',
  appleEpisodeLink: '',
  spotifyLink: '',
  spotifyEpisodeLink: '',
  fbGroupLink: '',
  episode: '',
  listenToAtLeast: 20,
  mainImage: '',
  surveysRequested: '',
  listenNowImageLink: '',
  questions: [
    {
      answers: [
        {
          answer: '1: "Did not enjoy it"',
          value: '1',
        },
        {
          answer: '2',
          value: '2',
        },
        {
          answer: '3',
          value: '3',
        },
        {
          answer: '4',
          value: '4',
        },
        {
          answer: '5',
          value: '5',
        },
        {
          answer: '6',
          value: '6',
        },
        {
          answer: '7',
          value: '7',
        },
        {
          answer: '8',
          value: '8',
        },
        {
          answer: '9',
          value: '9',
        },
        {
          answer: '10: "Loved it!"',
          value: '10',
        },
      ],
      question:
        'Did you enjoy listening to this podcast? Please provide an answer between 1 and 10.',
      type: 'question',
      inputType: 'radio',
      fieldName: '1',
    },
    {
      answers: [],
      question: 'What feedback would you give to the host of the show to improve the podcast?',
      type: 'question',
      inputType: 'textarea',
      fieldName: '2',
    },
    {
      answers: [
        {
          answer: 'Yes',
          value: 'yes',
        },
        {
          answer: 'No',
          value: 'no',
        },
      ],
      question: 'Will you continue listening to the podcast?',
      type: 'question',
      inputType: 'radio',
      fieldName: '3',
    },
  ],
  quiz: [
    {
      question: '',
      type: 'test',
      inputType: 'radio',
      fieldName: '1',
      correctAnswer: '',
      answers: [
        {
          answer: '',
          value: 'a',
        },
      ],
    },
  ],
  answers: {},
};
