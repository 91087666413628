import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSurveys } from '../../../../api/admin';
import {
  setSurveys,
  setLoading,
  setError,
  setPagination,
  setSort,
} from '../../../../store/slices/surveySlice';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  Typography,
  Box,
  CircularProgress,
  Alert,
  IconButton,
  InputAdornment,
  TextField,
  Backdrop,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import SearchIcon from '@mui/icons-material/Search';
import debounce from 'lodash/debounce';
import styled from '@emotion/styled';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PeopleIcon from '@mui/icons-material/People';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-input': {
    color: '#fff',
  },
  '& .MuiInputAdornment-root .MuiSvgIcon-root': {
    color: '#fff',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.23)',
      backgroundColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.87)',
      backgroundColor: 'transparent',
    },
  },
});

const SurveyList = () => {
  const dispatch = useDispatch();
  const { surveys, loading, error, pagination, sort } = useSelector((state) => state.surveys);
  const { page, limit, total } = pagination;
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [totalRespondents, setTotalRespondents] = useState(0);

  useEffect(() => {
    fetchSurveys();
  }, [page, limit, searchQuery, sort]);

  const debouncedSearch = useCallback(
    debounce((term) => {
      setSearchQuery(term);
      dispatch(setPagination({ page: 1, limit, search: searchQuery }));
    }, 1000),
    []
  );

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };

  const handleSearchKeyPress = (event) => {
    if (event.key === 'Enter') {
      debouncedSearch.cancel();
      setSearchQuery(searchTerm);
      dispatch(setPagination({ page: 1, limit, search: searchQuery }));
    }
  };

  const fetchSurveys = async () => {
    try {
      dispatch(setLoading(true));
      const response = await getSurveys({
        page,
        limit,
        search: searchQuery,
        sortBy: sort.field,
        sortOrder: sort.direction,
      });
      dispatch(setSurveys(response.docs));
      dispatch(
        setPagination({
          total: response.totalDocs,
          totalPages: response.totalPages,
        })
      );
      setTotalRespondents(response.totalRespondents);
    } catch (err) {
      const errorMessage = err.message || 'Failed to fetch surveys';
      dispatch(setError(errorMessage));
      toast.error(errorMessage);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleChangePage = (event, newPage) => {
    dispatch(setPagination({ page: newPage + 1 }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(
      setPagination({
        limit: parseInt(event.target.value, 10),
        page: 1,
      })
    );
  };

  const handleEdit = (surveyId) => {
    navigate(`/admin/surveys/edit/${surveyId}`);
  };

  const handleCreate = () => {
    navigate('/admin/surveys/create');
  };

  const handleSort = (field) => {
    const newDirection = sort.field === field && sort.direction === 'asc' ? 'desc' : 'asc';
    dispatch(setSort({ field, direction: newDirection }));
  };

  if (error) {
    return (
      <Box p={3}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box p={3}>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Box>
          <Typography variant="h4" component="h1">
            Surveys
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            Total Respondents: {totalRespondents}
          </Typography>
        </Box>
        <Button variant="contained" color="primary" onClick={handleCreate}>
          Create New Survey
        </Button>
      </Box>

      <Box mb={3}>
        <StyledTextField
          fullWidth
          variant="outlined"
          placeholder="Search surveys..."
          value={searchTerm}
          onChange={handleSearch}
          onKeyUp={handleSearchKeyPress}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell 
                onClick={() => handleSort('_id')}
                sx={{ cursor: 'pointer' }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  #
                  {sort.field === '_id' && (
                    sort.direction === 'asc' ? <ArrowDropUpIcon sx={{ ml: 0.5 }} /> : <ArrowDropDownIcon sx={{ ml: 0.5 }} />
                  )}
                </Box>
              </TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Episode</TableCell>
              <TableCell>Respondents</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {surveys.map((survey, i) => (
              <TableRow key={survey._id} hover>
                <TableCell>
                  {sort.direction === 'asc' 
                    ? ++i + (page - 1) * limit 
                    : total - (i + (page - 1) * limit)}
                </TableCell>
                <TableCell>{survey.name}</TableCell>
                <TableCell>{survey.episode}</TableCell>
                <TableCell>{`${survey.respondentCount} / ${survey.surveysRequested || 0}`}</TableCell>
                <TableCell align="right">
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                    <IconButton
                      component={Link}
                      title="View Respondents"
                      to={`/admin/respondents?sid=${survey._id}`}
                      size="small"
                      color="primary"
                    >
                      <PeopleIcon />
                    </IconButton>
                    <IconButton
                      color="primary"
                      size="small"
                      title="View Survey"
                      component={Link}
                      to={`/survey/${survey.slug}`}
                      target="_blank"
                      rel="noopener"
                    >
                      <VisibilityIcon />
                    </IconButton>
                    <IconButton
                      color="primary"
                      size="small"
                      title="Edit"
                      onClick={() => handleEdit(survey._id)}
                    >
                      <EditIcon />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={total}
          rowsPerPage={limit}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
              fontSize: { xs: '0.8rem', sm: '1rem' },
              marginBottom: 0,
            },
          }}
        />
      </TableContainer>
    </Box>
  );
};

export default SurveyList;
