import React from 'react';
import { 
  Box, 
  Grid, 
  IconButton, 
  MenuItem,
  Typography,
  Divider,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { FieldArray, useField } from 'formik';
import { FormField } from './FormField';

const inputTypes = [
  { value: 'radio', label: 'Radio' },
  { value: 'textarea', label: 'Textarea' },
];

const getLetterValue = (index) => {
  return String.fromCharCode(97 + index); // 97 is ASCII for 'a'
};

const Question = ({ name, onRemove, type = 'question', showInputType = false }) => {
  const [field] = useField(name);
  const answersName = `${name}.answers`;

  return (
    <Box sx={{ p: 2, mb: 2, border: '1px solid rgba(255, 255, 255, 0.23)', borderRadius: 1 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h6">{type === 'test' ? 'Quiz Question' : 'Survey Question'}</Typography>
            <IconButton onClick={onRemove} color="error" size="small">
              <DeleteIcon />
            </IconButton>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <FormField
            name={`${name}.question`}
            label="Question Text"
            required
            multiline
            rows={2}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormField
            name={`${name}.fieldName`}
            label="Field Name"
            helperText="Identifier for this question in the database"
          />
        </Grid>

        {showInputType && (
          <Grid item xs={12} md={6}>
            <FormField
              name={`${name}.inputType`}
              label="Input Type"
              required
              select
            >
              {inputTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </FormField>
          </Grid>
        )}

        {type === 'test' && (
          <Grid item xs={12} md={6}>
            <FormField
              name={`${name}.correctAnswer`}
              label="Correct Answer"
              required
              select
            >
              {field.value.answers?.map((answer, index) => (
                <MenuItem 
                  key={index} 
                  value={answer.value}
                >
                  {answer.answer || `Answer ${index + 1}`}
                </MenuItem>
              ))}
            </FormField>
          </Grid>
        )}

        {field.value.inputType !== 'textarea' && (
          <Grid item xs={12}>
            <Divider sx={{ my: 2 }} />
            <FieldArray
              name={answersName}
              render={arrayHelpers => (
                <Box>
                  <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Typography variant="subtitle1">Answers</Typography>
                    <IconButton
                      onClick={() => {
                        const currentLength = field.value.answers?.length || 0;
                        const letterValue = getLetterValue(currentLength);
                        arrayHelpers.push({ 
                          answer: '', 
                          value: letterValue,
                        });
                      }}
                      color="primary"
                      size="small"
                    >
                      <AddIcon />
                    </IconButton>
                  </Box>
                  
                  {field.value.answers?.map((_, index) => (
                    <Box key={index} display="flex" gap={2} mb={2}>
                      <Typography
                        sx={{
                          width: '30px',
                          display: 'flex',
                          alignItems: 'center',
                          color: 'text.secondary'
                        }}
                      >
                        {getLetterValue(index)}.
                      </Typography>
                      <FormField
                        name={`${answersName}.${index}.answer`}
                        label="Answer Text"
                        required
                        sx={{ flex: 1 }}
                      />
                      {field.value.answers.length > 1 && (
                        <IconButton
                          onClick={() => arrayHelpers.remove(index)}
                          color="error"
                          size="small"
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </Box>
                  ))}
                </Box>
              )}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Question; 