export const getSurveyAnswers = () => {
  const surveyData = JSON.parse(sessionStorage.getItem('survey')) || {};
  return surveyData;
};

export const updateSurveyAnswers = (step, newAnswers) => {
  const surveyData = JSON.parse(sessionStorage.getItem('survey')) || {};

  surveyData[step] = {
    ...surveyData[step],
    ...newAnswers,
  };

  sessionStorage.setItem('survey', JSON.stringify(surveyData));
};

export const getFailedQuizAmount = (surveyId) => {
  const surveyTries = JSON.parse(localStorage.getItem('surveyFailedTries')) || [];
  const survey = surveyTries.find((s) => s.surveyId === surveyId);
  return survey ? survey.failedTries : 0;
};

export const setFailedQuizAmount = (surveyId) => {
  const surveyTries = JSON.parse(localStorage.getItem('surveyFailedTries')) || [];
  const survey = surveyTries.find((s) => s.surveyId === surveyId);

  if (survey) {
    survey.failedTries += 1;
  } else {
    surveyTries.push({ surveyId, failedTries: 1 });
  }

  localStorage.setItem('surveyFailedTries', JSON.stringify(surveyTries));
};

export const checkIntroductionFilled = (answers) => {
  if (!answers.introduction) {
    return false;
  }

  const { email, name } = answers.introduction;

  return !!email && !!name;
};

export const scrollToRef = (ref) => {
  if (ref && ref.current) {
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }
};

export const parseJwt = (token) => {
  try {
    // Split the token into parts and get the payload (second part)
    const base64Payload = token.split('.')[1];
    // Replace URL-safe characters and create valid base64
    const base64 = base64Payload.replace(/-/g, '+').replace(/_/g, '/');
    // Decode the base64 string and parse as JSON
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    );
    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error('Error parsing JWT:', error);
    return null;
  }
};

export const getHeaders = () => {
  const authToken = localStorage.getItem('auth_token');
  return {
    JWTAuthorization: authToken || '',
  };
};
