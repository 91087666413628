import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Chip,
  Paper,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { toast } from 'react-toastify';
import { getBlacklist, addToBlacklist, removeFromBlacklist } from '../../../api/admin';

const BlackList = () => {
  const [emails, setEmails] = useState([]);
  const [newEmails, setNewEmails] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [addingEmails, setAddingEmails] = useState(false);

  // Filter emails based on search term
  const filteredEmails = emails.filter(email =>
    email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    fetchBlacklist();
  }, []);

  const fetchBlacklist = async () => {
    try {
      setLoading(true);
      const response = await getBlacklist();
      setEmails(response || []);
    } catch (error) {
      toast.error('Failed to load blacklisted emails');
    } finally {
      setLoading(false);
    }
  };

  const handleAddEmails = async () => {
    const emailList = newEmails
      .split(/[\n,]/)
      .map(email => email.trim())
      .filter(email => email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email));

    if (emailList.length > 0) {
      try {
        setAddingEmails(true);
        await addToBlacklist(emailList);
        setEmails(prev => [...new Set([...prev, ...emailList])]);
        setNewEmails('');
        toast.success('Emails successfully added to blacklist');
      } catch (error) {
        toast.error(error.message || 'Failed to add emails to blacklist');
      } finally {
        setAddingEmails(false);
      }
    }
  };

  const handleDeleteEmail = async (emailToDelete) => {
    try {
      await removeFromBlacklist(emailToDelete);
      setEmails(emails.filter(email => email !== emailToDelete));
      toast.success('Email successfully removed from blacklist');
    } catch (error) {
      toast.error(error.message || 'Failed to remove email from blacklist');
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Email Blacklist
      </Typography>

      {/* Search Field */}
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search emails..."
        value={searchTerm}
        onChange={handleSearch}
        sx={{ mb: 3 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

      {/* Email Input */}
      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Add Emails
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={4}
          value={newEmails}
          onChange={(e) => setNewEmails(e.target.value)}
          placeholder="Enter emails (separated by commas or new lines)"
          sx={{ mb: 2 }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddEmails}
          disabled={!newEmails.trim() || addingEmails}
          startIcon={addingEmails && <CircularProgress size={20} color="inherit" />}
        >
          {addingEmails ? 'Adding...' : 'Add to Blacklist'}
        </Button>
      </Paper>

      {/* Email List */}
      <Paper sx={{ p: 3 }}>
        <Typography variant="h6" gutterBottom>
          Blacklisted Emails
        </Typography>
        {filteredEmails.length === 0 ? (
          <Typography color="textSecondary">
            {searchTerm ? 'No emails found matching your search' : 'No emails in blacklist'}
          </Typography>
        ) : (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {filteredEmails.map((email) => (
              <Chip
                key={email}
                label={email}
                onDelete={() => handleDeleteEmail(email)}
                color="default"
                sx={{ m: 0.5 }}
              />
            ))}
          </Box>
        )}
      </Paper>
    </Container>
  );
};

export default BlackList; 