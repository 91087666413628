import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  surveys: [],
  loading: false,
  error: null,
  pagination: {
    page: 1,
    totalPages: 1,
    limit: 10,
    total: 0
  },
  sort: {
    field: '_id',
    direction: 'asc'
  }
};

const surveySlice = createSlice({
  name: 'surveys',
  initialState,
  reducers: {
    setSurveys: (state, action) => {
      state.surveys = action.payload;
      state.loading = false;
      state.error = null;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    setPagination: (state, action) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload
      };
    },
    setSort: (state, action) => {
      state.sort = action.payload;
    }
  }
});

export const { setSurveys, setLoading, setError, setPagination, setSort } = surveySlice.actions;
export default surveySlice.reducer; 