import * as Yup from 'yup';

const answerSchema = Yup.object({
  answer: Yup.string().required('Answer text is required'),
  value: Yup.string().required('Value is required'),
});

const questionSchema = Yup.object({
  question: Yup.string().required('Question text is required'),
  inputType: Yup.string().required('Input type is required')
    .oneOf(['radio', 'textarea'], 'Invalid input type'),
  fieldName: Yup.string().required('Field name is required'),
  correctAnswer: Yup.string().when('type', {
    is: 'test',
    then: () => Yup.string().required('Correct answer is required'),
    otherwise: () => Yup.string(),
  }),
  answers: Yup.array().when('inputType', {
    is: (inputType) => inputType !== 'textarea',
    then: () => Yup.array().of(answerSchema).min(1, 'At least one answer is required'),
    otherwise: () => Yup.array()
  }),
});

export const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  slug: Yup.string().required('Slug is required'),
  episode: Yup.string().required('Episode is required'),
  rssLink: Yup.string().url('Must be a valid URL').required('RSS Link is required'),
  appleLink: Yup.string().url('Must be a valid URL'),
  appleEpisodeLink: Yup.string().url('Must be a valid URL'),
  spotifyLink: Yup.string().url('Must be a valid URL'),
  spotifyEpisodeLink: Yup.string().url('Must be a valid URL'),
  fbGroupLink: Yup.string().url('Must be a valid URL'),
  mainImage: Yup.mixed(),
  questions: Yup.array().of(questionSchema),
  quiz: Yup.array().of(questionSchema)
    .min(1, 'At least one quiz question is required'),
  answers: Yup.object(),
}); 