import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  CircularProgress,
  Box,
  Typography,
  FormControl,
  TextField,
  MenuItem,
  IconButton,
  styled,
  Tooltip,
  useTheme,
  useMediaQuery,
  InputAdornment,
  Button,
} from '@mui/material';
import {
  FirstPage as FirstPageIcon,
  LastPage as LastPageIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Search as SearchIcon,
  FileDownload as FileDownloadIcon,
} from '@mui/icons-material';
import { getRespondents, downloadRespondentsCsv } from '../../../api/admin';
import {
  setRespondents,
  setLoading,
  setError,
  setPagination,
  setSorting,
} from '../../../store/slices/respondentSlice';
import { getSurveys } from '../../../api/admin';
import moment from 'moment';
import _ from 'lodash';
import { useSearchParams } from 'react-router-dom';

const StyledField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.23)',
      backgroundColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.87)',
      backgroundColor: 'transparent',
    },
  },
});

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
}

const RespondentsPage = () => {
  const dispatch = useDispatch();
  const { respondents, loading, pagination, sorting } = useSelector((state) => state.respondents);
  const { page, limit, total } = pagination;
  const { sortOrder, sortBy } = sorting;
  const [selectedSurvey, setSelectedSurvey] = useState('');
  const [surveys, setSurveys] = useState([]);
  const [surveysLoading, setSurveysLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [searchParams] = useSearchParams();

  // Create debounced search function
  const debouncedSetSearch = useCallback(
    _.debounce((value) => {
      setDebouncedSearchQuery(value);
    }, 1000),
    []
  );

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value);
    debouncedSetSearch(value);
  };

  const handleSearchKeyDown = (event) => {
    if (event.key === 'Enter') {
      debouncedSetSearch.cancel(); // Cancel any pending debounce
      setDebouncedSearchQuery(event.target.value); // Immediately apply the search
    }
  };

  const baseColumns = [
    {
      label: '#',
      render: ({ i, total }) => {
        if (sortBy === '_id' && sortOrder === 'desc') {
          return total - (i + (page - 1) * limit);
        }
        return i + 1 + (page - 1) * limit;
      },
      sortable: true,
      onSort: () => {
        dispatch(
          setSorting({
            sortBy: '_id',
            sortOrder: sortOrder === 'asc' ? 'desc' : 'asc',
          })
        );
        dispatch(setPagination({ page: 1 }));
      },
    },
    { label: 'Email', id: 'email' },
    { label: 'Name', id: 'introduction.name' },
    { label: 'IP', id: 'introduction.ip' },
    {
      label: 'Date',
      id: 'createdAt',
      render: ({ value }) => moment(value).format('YYYY-MM-DD HH:mm'),
    },
    { label: 'Gender', id: 'introduction.gender' },
    { label: 'Genres', id: 'introduction.genres', render: ({ value }) => value?.join(', ') },
    {
      label: 'Other Podcasts',
      id: 'introduction.other-podcasts',
    },
    {
      label: 'Sub2',
      id: 'introduction.sub2',
      sx: { maxWidth: 150 },
    },
    { label: 'Country', id: 'introduction.country' },
  ];

  const [columns, setColumns] = useState(baseColumns);

  const fetchSurveys = async () => {
    try {
      setSurveysLoading(true);
      const response = await getSurveys({ page: 1 }); // Get all surveys for filter
      setSurveys(response.docs);
    } catch (err) {
      console.error('Failed to fetch surveys:', err);
    } finally {
      setSurveysLoading(false);
    }
  };

  useEffect(() => {
    fetchSurveys();
    // Set selected survey from URL parameter if it exists
    const surveyId = searchParams.get('sid');
    if (surveyId) {
      setSelectedSurvey(surveyId);
    }
  }, []); // Only run on mount

  const fetchRespondents = async () => {
    try {
      dispatch(setLoading(true));
      const response = await getRespondents({
        page,
        limit,
        survey: selectedSurvey,
        sortBy,
        sortOrder,
        search: debouncedSearchQuery,
      });

      // Add question columns if they exist in the response
      if (response.questions?.length) {
        const questionColumns = response.questions.map((q) => ({
          label: q.question,
          id: `feedback.${q.fieldName}`,
          sx: { maxWidth: 400 },
        }));
        setColumns([...baseColumns, ...questionColumns]);
      } else {
        setColumns(baseColumns);
      }
      dispatch(setRespondents(response.docs));
      dispatch(
        setPagination({
          total: response.totalDocs,
          totalPages: response.totalPages,
        })
      );
    } catch (err) {
      dispatch(setError(err.message || 'Failed to fetch respondents'));
    }
  };

  useEffect(() => {
    if (selectedSurvey) {
      fetchRespondents();
    } else {
      dispatch(setRespondents([]));
      dispatch(
        setPagination({
          total: 0,
          totalPages: 1,
          page: 1,
        })
      );
    }
  }, [page, limit, selectedSurvey, sortOrder, sortBy, debouncedSearchQuery]);

  const handleChangePage = (event, newPage) => {
    dispatch(setPagination({ page: newPage + 1 }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(
      setPagination({
        limit: parseInt(event.target.value, 10),
        page: 1,
      })
    );
  };

  const handleSurveyChange = (event) => {
    setSelectedSurvey(event.target.value);
    dispatch(setPagination({ page: 1 })); // Reset to first page when filter changes
  };

  const selectedSurveyName = surveys.find((s) => s._id === selectedSurvey)?.name;

  const handleDownloadCsv = async () => {
    try {
      await downloadRespondentsCsv(selectedSurvey);
    } catch (error) {
      console.error('Failed to download CSV:', error);
      // You might want to show a toast notification here
    }
  };

  return (
    <Box sx={{ width: '100%', p: { xs: 1, sm: 3 } }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
          alignItems: { xs: 'stretch', sm: 'center' },
          mb: 3,
          gap: 2,
        }}
      >
        <Typography variant="h4" component="h1" sx={{ fontSize: { xs: '1.5rem', sm: '2.125rem' } }}>
          Respondents
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: 2,
            width: { xs: '100%', sm: 'auto' },
            alignItems: 'center'
          }}
        >
          {!surveysLoading && (
            <>
              <FormControl sx={{ width: { xs: '100%', sm: 300 } }}>
                <StyledField
                  value={selectedSurvey}
                  onChange={handleSurveyChange}
                  label="Filter by Survey"
                  select
                >
                  <MenuItem value="">
                    <em>Select a survey</em>
                  </MenuItem>
                  {surveys.map((survey) => (
                    <MenuItem key={survey._id} value={survey._id}>
                      {survey.name}
                    </MenuItem>
                  ))}
                </StyledField>
              </FormControl>
            </>
          )}
        </Box>
      </Box>

      <Box sx={{ mb: 2, width: { xs: '100%' } }}>
        <StyledField
          fullWidth
          value={searchQuery}
          onChange={handleSearchChange}
          onKeyDown={handleSearchKeyDown}
          placeholder="Search respondents..."
          disabled={!selectedSurvey || loading || surveysLoading}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
          <CircularProgress />
        </Box>
      ) : !selectedSurvey ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '200px',
          }}
        >
          <Typography variant="h6" color="text.secondary">
            Please select a survey to view respondents
          </Typography>
        </Box>
      ) : (
        <>
          <Typography variant="h5" sx={{ mb: 1, fontSize: { xs: '1.25rem', sm: '1.5rem' } }}>
            {selectedSurveyName}
          </Typography>
          {selectedSurvey && (
            <Box sx={{ mb: 2, display: 'flex', gap: 2 }}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<FileDownloadIcon />}
                onClick={handleDownloadCsv}
                sx={{ 
                  whiteSpace: 'nowrap',
                  height: '36px',
                  fontSize: { xs: '0.8rem', sm: '0.9rem' }
                }}
              >
                Get CSV
              </Button>
              <Button
                variant="contained"
                color="secondary"
                component="a"
                href="http://34.196.164.23/"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ 
                  whiteSpace: 'nowrap',
                  height: '36px',
                  fontSize: { xs: '0.8rem', sm: '0.9rem' }
                }}
              >
                Survey Insights
              </Button>
            </Box>
          )}
          <Paper sx={{ width: '100%', mb: 2 }}>
            <TableContainer
              sx={{
                maxHeight: { xs: 'calc(100vh - 300px)', sm: 'calc(100vh - 250px)' },
                overflowX: 'auto',
              }}
            >
              <Table
                stickyHeader
                sx={{
                  minWidth: { xs: 'max-content', sm: 1500 },
                  '& .MuiTableCell-root': {
                    px: { xs: 1, sm: 2 },
                    py: { xs: 1, sm: 1.5 },
                    fontSize: { xs: '0.8rem', sm: '1rem' },
                  },
                }}
              >
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.label}
                        sx={{
                          whiteSpace: 'nowrap',
                          backgroundColor: 'background.paper',
                          maxWidth: 250,
                          cursor: column.sortable ? 'pointer' : 'default',
                          '&:hover': column.sortable
                            ? {
                                backgroundColor: 'action.hover',
                              }
                            : {},
                        }}
                        onClick={() => column.sortable && column.onSort()}
                      >
                        <Tooltip title={column.label} placement="top">
                          <Box
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              display: 'flex',
                              alignItems: 'center',
                              gap: 0.5,
                            }}
                          >
                            {column.label}
                            {column.sortable && sortBy === '_id' && (
                              <Box component="span" sx={{ ml: 0.5 }}>
                                {sortOrder === 'asc' ? '↑' : '↓'}
                              </Box>
                            )}
                          </Box>
                        </Tooltip>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {respondents.map((row, i) => (
                    <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      {columns.map((column) => (
                        <TableCell
                          key={`${row.id}-${column.id}`}
                          sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            ...(column.sx || {}),
                          }}
                        >
                          <Tooltip
                            title={
                              column.render
                                ? column.render({
                                    value: _.get(row, column.id),
                                    i: i,
                                    total: total,
                                  })
                                : _.get(row, column.id, 'N/A')
                            }
                            placement="top"
                          >
                            <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                              {column.render
                                ? column.render({
                                    value: _.get(row, column.id),
                                    i: i,
                                    total: total,
                                  })
                                : _.get(row, column.id, 'N/A')}
                            </Box>
                          </Tooltip>
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={isMobile ? [5, 10] : [5, 10, 25]}
              component="div"
              count={total}
              rowsPerPage={limit}
              page={page - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              sx={{
                '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
                  fontSize: { xs: '0.8rem', sm: '1rem' },
                  marginBottom: 0,
                },
              }}
            />
          </Paper>
        </>
      )}
    </Box>
  );
};

export default RespondentsPage;
