import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  respondents: [],
  loading: false,
  error: null,
  pagination: {
    page: 1,
    totalPages: 1,
    limit: 10,
    total: 0
  },
  sorting: {
    sortBy: '_id',
    sortOrder: 'desc',
  },
};

const respondentSlice = createSlice({
  name: 'respondents',
  initialState,
  reducers: {
    setRespondents: (state, action) => {
      state.respondents = action.payload;
      state.loading = false;
      state.error = null;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    setPagination: (state, action) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload
      };
    },
    setSorting: (state, action) => {
      state.sorting = {
        ...state.sorting,
        ...action.payload,
      };
    },
  }
});

export const { setRespondents, setLoading, setError, setPagination, setSorting } = respondentSlice.actions;
export default respondentSlice.reducer; 