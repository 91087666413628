import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Paper,
  Button,
  Typography,
  Box,
  CircularProgress,
  Alert,
  Grid,
  Backdrop,
  FormHelperText
} from '@mui/material';
import { toast } from 'react-toastify';
import { Formik, Form, useField, FieldArray } from 'formik';
import { getSurveyById, updateSurvey, createSurvey, uploadImage } from '../../../../api/admin';
import { validationSchema } from './validationSchema';
import { initialValues } from './initialValues';
import Question from './Question';
import AddIcon from '@mui/icons-material/Add';
import { FormField as Field } from './FormField';
import { generateSlug } from '../../../../utils/stringUtils';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

const FormField = ({ name, ...rest }) => {
  const [field, meta] = useField(name);
  return (
    <Field
      fullWidth
      {...field}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      {...rest}
    />
  );
};

const ImagePreview = ({ src }) => {
  if (!src) return null;
  return (
    <Box mt={1}>
      <img 
        src={src} 
        alt="Preview" 
        style={{ 
          maxWidth: '200px', 
          maxHeight: '200px', 
          objectFit: 'contain' 
        }} 
      />
    </Box>
  );
};

const SurveyForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setLocalError] = useState('');
  const [formData, setFormData] = useState(initialValues);
  const [mainImagePreview, setMainImagePreview] = useState(null);
  const fileInputRef = React.useRef(null);
  const [removedQuestionIds, setRemovedQuestionIds] = useState([]);

  const fetchSurvey = useCallback(async () => {
    try {
      setLoading(true);
      const survey = await getSurveyById(id);
      
      // Transform the answers object into correctAnswer fields
      const transformedSurvey = {
        ...survey,
        quiz: survey.quiz.map((question, index) => ({
          ...question,
          correctAnswer: survey.answers[question.fieldName] || ''
        }))
      };

      if (survey.mainImage) {
        setMainImagePreview(survey.mainImage);
      }

      setFormData(transformedSurvey);
    } catch (err) {
      setLocalError(err.message || 'Failed to fetch survey');
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      fetchSurvey();
    }
  }, [id, fetchSurvey]);

  const handleRemoveQuestion = (question, arrayHelpers, index) => {
    if (question._id) {
      setRemovedQuestionIds(prev => [...prev, question._id]);
    }
    arrayHelpers.remove(index);
  };

  const formatAnswersBeforeSubmit = (values) => {
    const answers = {};
    
    values.quiz.forEach((question, index) => {
      if (question.correctAnswer) {
        answers[question.fieldName] = question.correctAnswer;
      }
    });

    return {
      ...values,
      answers,
      removedQuestions: removedQuestionIds,
    };
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setLoading(true);
      let imageUrl = '';
      if (values.mainImage instanceof File) {
        const uploadResult = await uploadImage(values.mainImage);
        imageUrl = uploadResult.url;
      }

      const formattedValues = formatAnswersBeforeSubmit({
        ...values,
        mainImage: imageUrl || values.mainImage,
      });
      if (id) {
        await updateSurvey(id, formattedValues);
        toast.success('Survey updated successfully');
      } else {
        await createSurvey(formattedValues);
        toast.success('Survey created successfully');
      }
      
      navigate('/admin/surveys');
    } catch (err) {
      const errorMessage = err.message || 'Failed to save survey';
      setLocalError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  const generateUniqueFieldName = (values, prefix) => {
    // Get all existing fieldNames depending on the prefix type
    const existingNames = prefix === 'quiz_' 
      ? (values.quiz || []).map(q => q.fieldName)
      : (values.questions || []).map(q => q.fieldName);
    
    // Find the highest number in existing fieldNames
    let highestNum = 0;
    existingNames.forEach(name => {
      // Extract numeric part
      const match = name.match(/\d+$/);
      if (match) {
        const num = parseInt(match[0]);
        if (!isNaN(num) && num > highestNum) {
          highestNum = num;
        }
      }
    });
    
    // Return next available number
    return `${highestNum + 1}`;
  };

  return (
    <Box p={3}>
      <Backdrop
        sx={{ 
          color: '#fff', 
          zIndex: (theme) => theme.zIndex.drawer + 1 
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Typography variant="h4" component="h1" mb={3}>
        {id ? 'Edit Survey' : 'Create New Survey'}
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      <Formik
        initialValues={id ? formData : initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, isSubmitting, setFieldValue, errors }) => (
          <Paper component={Form} sx={{ p: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <FormField
                  name="name"
                  label="Name"
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormField
                  name="episode"
                  label="Episode"
                  required
                  onChange={(e) => {
                    const episode = e.target.value;
                    setFieldValue('episode', episode);
                    setFieldValue('slug', generateSlug(episode));
                  }}
                />
                <FormHelperText sx={{ ml: 1 }}>
                  {values.slug && `survey.mowpod.com/survey/${values.slug}`}
                </FormHelperText>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormField
                  name="slug"
                  label="End of URL"
                  required
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormField
                  name="rssLink"
                  label="RSS Link"
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormField
                  name="appleLink"
                  label="Apple Link"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormField
                  name="appleEpisodeLink"
                  label="Apple Episode Link"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormField
                  name="spotifyLink"
                  label="Spotify Link"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormField
                  name="spotifyEpisodeLink"
                  label="Spotify Episode Link"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormField
                  name="fbGroupLink"
                  label="Facebook Group Link"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormField
                  name="listenToAtLeast"
                  label="Listen To At Least"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormField
                  name="surveysRequested"
                  label="Number of Surveys Requested"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormField
                  name="listenNowImageLink"
                  label="Main Image Link"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box>
                  <Typography variant="subtitle1" mb={1}>Main Image</Typography>
                  <Button
                    component="label"
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                    sx={{ mb: 1 }}
                  >
                    Upload Image
                    <VisuallyHiddenInput
                      ref={fileInputRef}
                      type="file"
                      accept="image/*"
                      onChange={(event) => {
                        const file = event.target.files[0];
                        if (file) {
                          const imageUrl = URL.createObjectURL(file);
                          setFieldValue('mainImage', file);
                          setMainImagePreview(imageUrl);
                        }
                      }}
                    />
                  </Button>
                  {mainImagePreview && (
                    <Box mt={1}>
                      <ImagePreview src={mainImagePreview} />
                      <Button 
                        size="small"
                        color="error"
                        onClick={() => {
                          setFieldValue('mainImage', null);
                          setMainImagePreview(null);
                          if (fileInputRef.current) {
                            fileInputRef.current.value = '';
                          }
                        }}
                        sx={{ mt: 1 }}
                      >
                        Remove Image
                      </Button>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>

            <Box mt={4}>
              <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                <Typography variant="h5">Quiz Questions</Typography>
              </Box>
              
              <FieldArray
                name="quiz"
                render={arrayHelpers => (
                  <Box>
                    <Box mb={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => arrayHelpers.insert(0, {
                          question: '',
                          type: 'test',
                          inputType: 'radio',
                          fieldName: generateUniqueFieldName(values, 'quiz_'),
                          correctAnswer: '',
                          answers: [{ 
                            answer: '', 
                            value: 'a',
                          }]
                        })}
                        startIcon={<AddIcon />}
                      >
                        Add Quiz Question
                      </Button>
                    </Box>

                    {values.quiz.map((quizItem, index) => (
                      <Question
                        {...quizItem}
                        key={index}
                        name={`quiz.${index}`}
                        onRemove={() => {
                          if (values.quiz.length > 1) {
                            handleRemoveQuestion(quizItem, arrayHelpers, index);
                          } else {
                            toast.error('At least one quiz question is required');
                          }
                        }}
                        showInputType={false}
                        index={index}
                      />
                    ))}
                  </Box>
                )}
              />
            </Box>

            <Box mt={4}>
              <Typography variant="h5" mb={2}>Survey Questions</Typography>
              <FieldArray
                name="questions"
                render={arrayHelpers => (
                  <Box>
                    <Box mb={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => arrayHelpers.insert(0, {
                          question: '',
                          inputType: 'textarea',
                          fieldName: generateUniqueFieldName(values, 'question_'),
                          answers: [{ 
                            answer: '', 
                            value: 'a',
                          }]
                        })}
                        startIcon={<AddIcon />}
                      >
                        Add Survey Question
                      </Button>
                    </Box>

                    {values.questions.map((questionItem, index) => (
                      <Question
                        key={index}
                        name={`questions.${index}`}
                        onRemove={() => handleRemoveQuestion(questionItem, arrayHelpers, index)}
                        type="question"
                        showInputType={true}
                        index={index}
                      />
                    ))}
                  </Box>
                )}
              />
            </Box>

            <Box mt={3} display="flex" gap={2}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting || loading}
              >
                {id ? 'Update Survey' : 'Create Survey'}
              </Button>
              <Button
                variant="outlined"
                onClick={() => navigate('/admin/surveys')}
              >
                Cancel
              </Button>
            </Box>
          </Paper>
        )}
      </Formik>
    </Box>
  );
};

export default SurveyForm; 