import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import surveyReducer from './slices/surveySlice';
import respondentReducer from './slices/respondentSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    surveys: surveyReducer,
    respondents: respondentReducer,
  },
}); 